import { Component } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalInnerComponent } from './modal-inner-component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-modal-pregunta-topnav',
  templateUrl: './modal-pregunta-topnav.component.html'
})
export class ModalPreguntaTopnavComponent {

  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService, private translateService: TranslateService) { }

  openModalWithComponent(): void {
    const initialState = {
      list: [
        '...',
        '..'
      ],
      title: "Crea tu pregunta"
    };
    this.bsModalRef = this.modalService.show(ModalInnerComponent, { initialState });
    this.bsModalRef.content.closeBtnName = "Cerrar";
  }

}
